/* eslint-disable max-len */
<template>
	<div>
		<template v-if="loading">
			<div class="div__loading">
				<Loader />
			</div>
		</template>
		<template v-else>
			<section class="main-grid__row user-profile">
				<div class="user-profile__card">
					<div class="user-profile__section">
						<h5 class="user-profile__heading">Basic Information</h5>

						<div class="user-profile__item">
							<p class="user-profile__section__title">Title:</p>
							<p id="faqTitle">{{ itemData.title }}</p>
						</div>
					</div>
				</div>
				<div class="user-profile__tabs" ref="tab">
					<ul class="nav nav-tabs" id="myTab" role="tablist">
						<li class="nav-item">
							<a
								class="nav-link active"
								id="profile-tab"
								data-toggle="tab"
								href="#profile"
								role="tab"
								aria-controls="profile"
								aria-selected="true"
							>
								Edit
							</a>
						</li>
					</ul>
					<div class="tab-content" id="myTabContent">
						<div
							class="tab-pane fade show active user-profile__tabs__section"
							id="profile"
							role="tabpanel"
							aria-labelledby="profile-tab"
						>
							<div class="">
								<form method="post" @submit.prevent="update">
									<div class="settings__field">
										<div class="form-row">
											<div class="form-group col-md-6">
												<div class="settings__value">
													<label for="title">Title</label>
													<input
														:class="[{ 'is-invalid': errors.title }]"
														@keydown="clearErrors($event.target.name)"
														v-model="itemData.title"
														name="title"
														type="text"
														class="form-control"
														id="title"
													/>
												</div>
												<div class="invalid-feedback" id="faqTitleError">
													{{ errors.title }}
												</div>
											</div>
											<div class="form-group col-md-12">
												<div class="settings__value">
													<label for="content">
														Content
													</label>
													<ckeditor
														:editor="editor"
														v-model="itemData.content"
														:config="editorConfig"
													></ckeditor>
												</div>
												<div
													class="invalid-feedback"
												>
													{{ errors.content }}
												</div>
											</div>
										</div>
										<div class="settings__primary">
											<button
												class="btn btn__save"
												type="button"
												name="button"
												@click.prevent="update"
												v-bind="{ disabled: loading }"
											>
												Update
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import form from '../../mixins/form';
import Loader from '../../components/Loader';
import faqs from '../../utils/validations/faqs';

export default {
	name: 'FaqDetails',
	mixins: [form],
	data() {
		return {
			itemData: {},
			errors: {},
			loading: true,
			editor: ClassicEditor,
			editorConfig: {
				toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList'],
				heading: {
					options: [
						{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
						{ model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
						{ model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
						{ model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
						{ model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
						{ model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
						{ model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
					]
				}
			}
		};
	},
	created() {
		const data = {
			id: this.$route.params.id,
			status: this.$route.query.status
		};
		this.fetchFaqDetails(data);
	},
	watch: {
		getStatus(value) {
			if (value === 'success') {
				this.itemData = this.getFaqDetails;
				this.loading = false;
			}
		}
	},
	computed: {
		...mapGetters(['getStatus', 'getFaqDetails']),
		statusStyle() {
			return [this.itemData.active === 0 ? 'badge badge-pill badge-danger' : 'badge badge-pill badge-success'];
		},
		faq() {
			return [this.itemData.active === 1 ? 'btn__danger' : 'btn__save--outline'];
		}
	},
	methods: {
		...mapActions(['updateFaqs', 'fetchFaqDetails']),

		update() {
			const data = this.itemData;
			Object.keys(data).forEach((key) => {
				if (data[key] === null) {
					delete data[key];
				}
			});
			['data', 'withdraw_date', 'is_deleted', 'is_deleted', 'updated_at', 'created_at', 'id'].forEach((item) => {
				delete data[item];
			});

			delete data.active;

			this.validate(data, faqs.create);
			if (Object.keys(this.issues).length > 0) {
				this.errors = this.issues;
				return false;
			}
			const payload = { data, id: this.$route.params.id };
			this.updateFaqs(payload);
			return true;
		},
		deactivateFaq() {
			this.itemData.active = this.itemData.active === 0 ? 1 : 0;
			const data = { active: this.itemData.active };
			const payload = { data, id: this.$route.params.id };
			this.updateFaqs(payload);
		}
	},
	components: {
		Loader
	}
};
</script>

<style lang="scss" scoped>
.settings {
	&--wide {
		max-width: 100%;
	}
	&__field {
		margin-top: 30px;
	}
	&__value {
		padding: 0;
		background: #fff;
		input {
			background: #fafafa;
			border: none;
			font-size: 14px;
			height: 36px;
		}
		label {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
	.row {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;
	}
}
.btn__wrapper {
	margin-top: 20px;
	.btn {
		&:not(:last-child) {
			margin-right: 10px;
		}
	}
}
.bold {
	font-weight: bold;
	font-size: 16px;
	margin-right: 5px;
}
</style>
